body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-position-x: 50%;
    background-position-y: 50%;
    background-origin: padding-box;
    background-clip: border-box;
    background-size: cover;
    background-attachment: fixed;
}
